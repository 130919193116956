
import { Theme } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { ExportFileType } from '../../../helpers/types'
import LoadingPreview from './loading-preview.vue'

@Component({
  components: {
    LoadingPreview
  },
  name: 'ShareDialogExportImagePreview'
})
export default class ShareDialogExportImagePreview extends Vue {
  @Prop() readonly selectedType!: ExportFileType
  @Prop() readonly selectedTheme!: Theme
  @Prop() readonly src!: string
  @Prop() readonly loading!: boolean
}
