
import { DiagramExportImage, DiagramExportImageFileType, DiagramExportPngOptions, DomainExport, FlowExport, ModelConnectionExport, ModelObjectExport, modelStatuses, PermissionType, TagExport, TagGroupExport, Theme, VersionExport } from '@icepanel/platform-api-client'
import * as FileSaver from 'file-saver'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import ContextMenuContent from '@/components/context-menu/content.vue'
import ContextMenu from '@/components/context-menu/index.vue'
import ContextMenuItem from '@/components/context-menu/item.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { TagModule } from '@/modules/tag/store'
import { TeamModule } from '@/modules/team/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'
import type { ExportCSVContent, ExportCSVContentItem, ExportFileItem, ExportFileType, ExportThemeItem } from '../../../helpers/types'
import { ShareModule } from '../../../store'
import BlankExportDialog from '../blank-export-dialog.vue'
import CsvPreview from './csv-preview.vue'
import EmptyState from './empty-state.vue'
import ImagePreview from './image-preview.vue'
import JsonPreview from './json-preview.vue'

@Component({
  components: {
    BlankExportDialog,
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    CsvPreview,
    EmptyState,
    ImagePreview,
    JsonPreview
  },
  name: 'ShareDialogExport'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  tagModule = getModule(TagModule, this.$store)
  teamModule = getModule(TeamModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly blankExportDialog!: BlankExportDialog
  @Ref() readonly fileTypeMenuRef!: ContextMenu
  @Ref() readonly themeMenuRef!: ContextMenu
  @Ref() readonly csvContentsMenuRef!: ContextMenu

  @Prop() readonly permission!: PermissionType

  loadingExportUrls: Record<Theme, boolean> = { dark: false, light: false }

  exportImages: Record<Theme, DiagramExportImage | null> = {
    dark: null,
    light: null
  }

  exportingJson = false
  exportJsonData: VersionExport | null = null
  exportJsonPreview: string | null = null

  exportingCsv = false
  exportCsvData: string | null = null
  exportCsvPreview: string | null = null

  exportingPdf = false
  exportedPDF = false

  downloading = false

  selectedType: ExportFileType = 'SVG'
  selectedTheme: Theme = 'dark'
  selectedCsvContent: ExportCSVContent = 'model_objects'

  get shortId () {
    return this.$params.shortId
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get toolbar () {
    return this.$queryValue('toolbar')
  }

  get currentFlowPathIds () {
    return this.$queryArray('flow_path')
  }

  get overlayTab () {
    return this.$queryValue('overlay_tab') as DiagramExportPngOptions['overlayTab'] | null
  }

  get overlayGroupId () {
    return this.$queryValue('overlay_group')
  }

  get overlayIdsPinned () {
    return this.$queryArray('overlay_pin')
  }

  get overlayIdsHidden () {
    return this.$queryArray('overlay_hide')
  }

  get overlayIdsFocused () {
    return this.$queryArray('overlay_focus')
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.diagramId === this.currentDiagram?.id && o.handleId === this.currentFlowHandleId)
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContent () {
    return Object.values(this.diagramModule.diagramContents).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramContentEmpty () {
    return !Object.keys(this.currentDiagramContent?.objects || {}).length
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get currentVersionModelDomain () {
    return this.currentVersionModel ? this.domainModule.domains[this.currentVersionModel.domainId] : undefined
  }

  get overlayGroup () {
    return Object.values(this.tagModule.tagGroups).find(o => o.handleId === this.overlayGroupId)
  }

  get overlaysPinned () {
    return [
      ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsPinned.includes(o.handleId) || this.overlayIdsPinned.includes(o.id)),
      ...Object.values(this.modelModule.technologies).filter(o => this.overlayIdsPinned.includes(o.id)),
      ...Object.values(modelStatuses).filter(o => this.overlayIdsPinned.includes(o.id)),
      ...this.teamModule.teams.filter(o => this.overlayIdsPinned.includes(o.id))
    ]
  }

  get overlaysHidden () {
    return [
      ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsHidden.includes(o.handleId) || this.overlayIdsHidden.includes(o.id)),
      ...Object.values(this.modelModule.technologies).filter(o => this.overlayIdsHidden.includes(o.id)),
      ...Object.values(modelStatuses).filter(o => this.overlayIdsHidden.includes(o.id)),
      ...this.teamModule.teams.filter(o => this.overlayIdsHidden.includes(o.id))
    ]
  }

  get overlaysFocused () {
    return [
      ...Object.values(this.tagModule.tags).filter(o => this.overlayIdsFocused.includes(o.handleId) || this.overlayIdsFocused.includes(o.id)),
      ...Object.values(this.modelModule.technologies).filter(o => this.overlayIdsFocused.includes(o.id)),
      ...Object.values(modelStatuses).filter(o => this.overlayIdsFocused.includes(o.id)),
      ...this.teamModule.teams.filter(o => this.overlayIdsFocused.includes(o.id))
    ]
  }

  get selectedTypeInfo () {
    return this.typeItems.find((t) => t.fileType === this.selectedType)
  }

  get imagePreviewUrl () {
    switch (this.selectedType) {
      case 'SVG':
        return this.exportImages[this.selectedTheme]?.fileUrls.svg ?? undefined
      case 'PNG':
        return this.exportImages[this.selectedTheme]?.fileUrls.png ?? undefined
    }
  }

  get showEmptyState (): boolean {
    switch (this.selectedType) {
      case 'PDF':
        return true
      case 'SVG':
        return !this.loadingExportUrls[this.selectedTheme] && this.exportImages[this.selectedTheme]?.fileUrls.svg === null
      case 'PNG':
        return !this.loadingExportUrls[this.selectedTheme] && this.exportImages[this.selectedTheme]?.fileUrls.png === null
    }

    return false
  }

  get typeItems (): ExportFileItem[] {
    return [
      {
        disabled: !this.$routeName?.endsWith('-diagram'),
        disabledMessage: 'SVG export only available for diagrams',
        fileType: 'SVG',
        icon: 'fad-file-svg',
        objectType: 'Diagram'
      },
      {
        disabled: !this.$routeName?.endsWith('-diagram'),
        disabledMessage: 'PNG export only available for diagrams',
        fileType: 'PNG',
        icon: 'fad-file-png',
        objectType: 'Diagram'
      },
      {
        fileType: 'PDF',
        icon: 'fad-file-pdf',
        objectType: 'Landscape'
      },
      {
        fileType: 'CSV',
        icon: 'fad-file-csv',
        objectType: 'Model'
      },
      {
        fileType: 'JSON',
        icon: 'fad-file-code',
        objectType: 'Model'
      }
    ]
  }

  get selectedThemeInfo () {
    return this.themeItems.find((t) => t.theme === this.selectedTheme)
  }

  get themeItems (): ExportThemeItem[] {
    return [
      {
        icon: 'fad-sun',
        name: 'Light',
        theme: 'light'
      },
      {
        icon: 'fad-moon',
        name: 'Dark',
        theme: 'dark'
      }
    ]
  }

  get selectedCsvContentInfo () {
    return this.csvContentItems.find((t) => t.content === this.selectedCsvContent)
  }

  get csvContentItems (): ExportCSVContentItem[] {
    return [
      {
        content: 'connections',
        icon: 'fad-arrow-right-arrow-left',
        name: 'Connections'
      },
      {
        content: 'model_objects',
        icon: 'fad-list',
        name: 'Model objects'
      }
    ]
  }

  get title () {
    if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type === 'root' && this.currentVersionModelDomain && this.currentOrganizationLimits.versionObjects && this.currentOrganizationLimits.domains > 1) {
      return `Export ${this.currentVersionModelDomain.name}`
    } else if (!this.latestVersion && this.currentVersionModel && this.currentVersionModel.type !== 'root' && this.currentOrganizationLimits.versionObjects) {
      return `Export ${this.currentVersionModel.name || `${this.currentVersionModel.type}`}`
    } else {
      return 'Export '
    }
  }

  get subtitle () {
    if (this.latestVersion) {
      return 'latest version'
    } else {
      return `version ${this.currentVersion.name}`
    }
  }

  get latestVersion () {
    return this.currentVersion.tags.includes('latest')
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get showShare () {
    return this.currentLandscapePermission && this.currentLandscapePermission !== 'read' && (this.currentOrganizationLimits.shareLinkVersions || this.currentVersion?.tags.includes('latest'))
  }

  @Watch('selectedType')
  onSelectedTypeChanged () {
    this.export()
  }

  @Watch('selectedCsvContent')
  onSelectedCsvContentChanged () {
    this.export()
  }

  mounted () {
    analytics.shareExportScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    this.selectedType = this.$routeName?.endsWith('-diagram') ? 'SVG' : 'PDF'

    if (!this.currentDiagramContentEmpty) {
      this.exportTheme('light')
      this.exportTheme('dark')
    }
  }

  async download () {
    this.downloading = true
    switch (this.selectedType) {
      case 'JSON':
        await this.downloadJson()
        break
      case 'CSV':
        await this.downloadCsv()
        break
      case 'PDF':
        await this.exportPdf()
        break
      case 'SVG':
        await this.downloadImage('svg')
        break
      case 'PNG':
        await this.downloadImage('png')
        break
      default:
        break
    }
    this.downloading = false
  }

  async export () {
    switch (this.selectedType) {
      case 'JSON': {
        this.exportJson()
        break
      }
      case 'CSV': {
        if (this.selectedCsvContent === 'connections') {
          this.exportCsvConnections()
        } else {
          this.exportCsvObjects()
        }
        break
      }
    }
  }

  async exportTheme (theme: Theme) {
    if (this.exportImages[theme] || !this.currentDiagram || this.loadingExportUrls[theme]) {
      return
    }
    this.loadingExportUrls[theme] = true

    let imageExport = await this.diagramModule.diagramExportImageCreate({
      diagramId: this.currentDiagram.id,
      landscapeId: this.currentLandscape.id,
      options: {
        flowId: this.currentFlow?.id,
        flowPathIds: this.currentFlowPathIds,
        focusIds: this.overlaysFocused.map(o => o.id),
        groupId: this.overlayGroup?.id,
        hideIds: this.overlaysHidden.map(o => o.id),
        pinIds: this.overlaysPinned.map(o => o.id),
        tab: this.overlayTab ?? undefined,
        theme
      },
      versionId: this.currentVersion.id
    })

    let attempts = 0
    do {
      await new Promise(resolve => setTimeout(resolve, 1000))

      imageExport = await this.diagramModule.diagramExportImageFind({
        diagramExportImageId: imageExport.id,
        diagramId: this.currentDiagram.id,
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      if (imageExport.error) {
        this.alertModule.pushAlert({
          color: 'error',
          message: imageExport.error
        })
        break
      }

      attempts++
      if (attempts > 60) {
        break
      }
    } while (!imageExport.completedAt)

    this.loadingExportUrls[theme] = false
    this.exportImages[theme] = imageExport
  }

  async downloadImage (fileType: DiagramExportImageFileType) {
    let url = this.exportImages[this.selectedTheme]?.fileUrls[fileType]

    if (!url) {
      let attempts = 0
      do {
        await new Promise(resolve => setTimeout(resolve, 1000))
        attempts++
        if (attempts > 60) {
          break
        }
        url = this.exportImages[this.selectedTheme]?.fileUrls[fileType]
      } while (!url)
    }

    if (url) {
      window.open(url)
    }

    if (!this.currentDiagram) {
      return
    }

    analytics.shareDiagramExportImage.track(this, {
      diagramFlowId: this.currentFlow?.id,
      diagramTheme: this.selectedTheme,
      diagramType: this.currentDiagram.type,
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareDiagramExportImageFileType: fileType,
      shareLinkShortId: this.shortId,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }

  async exportPdf () {
    const user = this.userModule.user
    try {
      if (this.exportingPdf || !user) {
        return
      }
      this.exportingPdf = true

      if (Object.keys(this.modelModule.objects).length) {
        const filename = `${this.currentLandscape.name} (${this.currentVersion.name}).pdf`
        await this.editorModule.landscapeExportPdf({
          email: user.email,
          filename,
          landscapeId: this.currentLandscape.id,
          name: user.name,
          organizationId: this.currentLandscape.organizationId,
          versionId: this.currentVersion.id
        })

        this.exportedPDF = true

        analytics.shareExportPdf.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkShortId: this.shortId,
          versionLatest: this.latestVersion,
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })

        this.alertModule.pushAlert({
          color: 'success',
          message: `You'll receive your export to ${user.email}, this shouldn't take more than 10 mins`
        })
      } else {
        this.blankExportDialog.open()
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingPdf = false
    }
  }

  async exportJson () {
    try {
      if (this.exportingJson) {
        return
      }
      this.exportingJson = true
      this.exportJsonPreview = null
      this.exportJsonData = null

      this.exportJsonData = await this.versionModule.versionExportJson({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })

      const jsonPreview: VersionExport = {
        ...this.exportJsonData,
        domains: Object
          .entries(this.exportJsonData.domains)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, DomainExport>),
        flows: Object
          .entries(this.exportJsonData.flows)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, FlowExport>),
        modelConnections: Object
          .entries(this.exportJsonData.modelConnections)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, ModelConnectionExport>),
        modelObjects: Object
          .entries(this.exportJsonData.modelObjects)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, ModelObjectExport>),
        tagGroups: Object
          .entries(this.exportJsonData.tagGroups)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, TagGroupExport>),
        tags: Object
          .entries(this.exportJsonData.tags)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, TagExport>),
        teams: Object
          .entries(this.exportJsonData.flows)
          .slice(0, 10)
          .reduce((p, [id, json]) => ({
            ...p,
            [id]: json
          }), {} as Record<string, FlowExport>)
      }
      this.exportJsonPreview = JSON.stringify(jsonPreview, null, 2)
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingJson = false
    }
  }

  async downloadJson () {
    let exportJsonData = this.exportJsonData

    let attempts = 0
    do {
      exportJsonData = this.exportJsonData
      if (exportJsonData) {
        const filename = `${this.currentLandscape.name} (${this.currentVersion.name}).json`
        const blob = new Blob([JSON.stringify(this.exportJsonData)], { type: 'application/json' })
        FileSaver.saveAs(blob, filename)
        break
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
      attempts++
      if (attempts > 60) {
        break
      }
    } while (!exportJsonData)

    analytics.shareExportJson.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareLinkShortId: this.shortId,
      versionLatest: this.latestVersion
    })
  }

  async exportCsvObjects () {
    try {
      if (this.exportingCsv) {
        return
      }
      this.exportingCsv = true
      this.exportCsvData = null
      this.exportCsvPreview = null

      this.exportCsvData = await this.modelModule.objectsExportCsv({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })
      this.exportCsvPreview = this.exportCsvData?.split('\n').slice(0, 40).join('\n') ?? null
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingCsv = false
    }
  }

  async exportCsvConnections () {
    try {
      if (this.exportingCsv) {
        return
      }
      this.exportingCsv = true
      this.exportCsvData = null
      this.exportCsvPreview = null

      this.exportCsvData = await this.modelModule.connectionsExportCsv({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })
      this.exportCsvPreview = this.exportCsvData?.split('\n').slice(0, 40).join('\n') ?? null
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'An error occured during export, please contact support'
      })
      throw err
    } finally {
      this.exportingCsv = false
    }
  }

  async downloadCsv () {
    let exportCsvData = this.exportCsvData

    let attempts = 0
    do {
      exportCsvData = this.exportCsvData
      if (exportCsvData) {
        const filename = `${this.currentLandscape.name} connections (${this.currentVersion.name}).csv`
        const blob = new Blob([exportCsvData], { type: 'application/csv' })
        FileSaver.saveAs(blob, filename)
        break
      }
      await new Promise(resolve => setTimeout(resolve, 1000))
      attempts++
      if (attempts > 60) {
        break
      }
    } while (!exportCsvData)

    if (this.selectedCsvContent === 'model_objects') {
      analytics.shareExportCsvObjects.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.shortId,
        versionLatest: this.latestVersion
      })
    } else if (this.selectedCsvContent === 'connections') {
      analytics.shareExportCsvConnections.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkShortId: this.shortId,
        versionLatest: this.latestVersion
      })
    }
  }
}
