
import { ShareLinkFrameLocation } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { SupportModule } from '@/modules/support/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'

@Component({
  name: 'ShareDialogEmbedLocations'
})
export default class ShareDialogEmbedLocations extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  supportModule = getModule(SupportModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly currentShareLinkUrl!: string
  @Prop() readonly copy!: string

  embedLocation: ShareLinkFrameLocation | 'other' | null = null

  requestedTool = ''
  toolRequestTimer?: number
  sentToolRequest = false
  requestInputFocused = false

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get latestVersion () {
    return this.currentVersion.tags.includes('latest')
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get sharingDocsUrl () {
    return 'https://docs.icepanel.io/features/sharing'
  }

  get currentShareLinkUrlWithFrame () {
    if (this.embedLocation && this.embedLocation !== 'other') {
      return `${this.currentShareLinkUrl}?frame=${this.embedLocation}`
    } else if (this.currentShareLinkUrl) {
      return this.currentShareLinkUrl
    } else {
      return ''
    }
  }

  get currentShareLinkFrame () {
    return `<iframe src="${this.currentShareLinkUrlWithFrame}" height="800" width="1200" title="${this.currentOrganization.name} - ${this.currentLandscape.name}" style="border-radius: 16px; border: none"></iframe>`
  }

  get currentEmbedLocation () {
    return this.embedLocations.find(o => o.id === this.embedLocation)
  }

  get embedLocations (): { id: ShareLinkFrameLocation, name: string, image: string, aspectRatio: string, docsUrl: string }[] {
    return [
      {
        aspectRatio: '1.0748898678',
        docsUrl: `${this.sharingDocsUrl}#embed-in-confluence`,
        id: 'confluence',
        image: require('../../../assets/embed/confluence.png'),
        name: 'Confluence Cloud'
      },
      {
        aspectRatio: '0.9821428571',
        docsUrl: `${this.sharingDocsUrl}#embed-in-notion`,
        id: 'notion',
        image: require('../../../assets/embed/notion.png'),
        name: 'Notion'
      },
      {
        aspectRatio: '1',
        docsUrl: `${this.sharingDocsUrl}#embed-in-miro`,
        id: 'miro',
        image: require('../../../assets/embed/miro.png'),
        name: 'Miro'
      },
      {
        aspectRatio: '1.0181818182',
        docsUrl: `${this.sharingDocsUrl}#embed-in-sharepoint`,
        id: 'sharepoint',
        image: require('../../../assets/embed/sharepoint.png'),
        name: 'SharePoint'
      },
      {
        aspectRatio: '1.0892857143',
        docsUrl: `${this.sharingDocsUrl}#embed-in-powerpoint---office-365`,
        id: 'powerpoint',
        image: require('../../../assets/embed/powerpoint.png'),
        name: 'PowerPoint (Office 365)'
      }
    ]
  }

  destroyed () {
    clearTimeout(this.toolRequestTimer)
    this.toolRequestTimer = undefined
  }

  @Watch('embedLocation')
  onEmbedLocationChanged (embedLocation: ShareLinkFrameLocation | null | 'other') {
    analytics.shareEmbedScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      shareLinkFrameLocation: embedLocation || undefined,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }

  async createRequest () {
    if (this.userModule.user) {
      await this.supportModule.supportMessageCreate({
        body: {
          toolName: this.requestedTool,
          type: 'embed-tool-request'
        },
        email: this.userModule.user.email,
        landscapeId: this.currentLandscape?.id,
        name: this.userModule.user.name,
        organizationId: this.currentOrganization?.id,
        url: window.location.href,
        userId: this.userModule.user.id
      })

      this.sentToolRequest = true
      this.requestedTool = ''
      clearTimeout(this.toolRequestTimer)
      this.toolRequestTimer = window.setTimeout(() => {
        this.sentToolRequest = false
      }, 2000)
    }
  }

  copyText (id: string, text: string) {
    this.$emit('copy-text', id, text, this.embedLocation)
  }

  goToEmbedHelp () {
    window.open(this.currentEmbedLocation ? this.currentEmbedLocation.docsUrl : `${this.sharingDocsUrl}#embedding-share-links`, '_blank')?.focus()
  }
}
