
import { ShareLinkFrameLocation, ShareLinkOptionsObjectTab, ShareLinkOptionsRequired } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../../helpers/analytics'
import { ShareModule } from '../../../store'
import EmbedLocations from './embed-locations.vue'

@Component({
  components: {
    EmbedLocations
  },
  name: 'ShareDialogEmbed'
})
export default class ShareDialogEmbed extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  loading = true
  loadingShareToggle = false
  loadingProtectedToggle = false
  loadingGenerateLink = false
  loadingGenerateOptions = false

  preventNavigation = false

  password = ''
  passwordInputFocused = false
  settingPassword = false
  showPassword = false

  copy: string | null = null
  copyTimer?: number

  get curretDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentShareLinkOptions () {
    return this.shareModule.shareLinkOptions?.shareLinkId === this.currentShareLink.id ? this.shareModule.shareLinkOptions : undefined
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.curretDiagramHandleId)
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get shareLinksEnabled () {
    return this.currentOrganization.shareLinkAuthDomains === null || this.currentOrganization.shareLinkAuthDomains.length !== 0
  }

  get shareLinkSSOEnabled () {
    return this.currentOrganization.shareLinkAuthDomains !== null && !!this.currentOrganization.shareLinkAuthDomains.length
  }

  get shareLinkDraft () {
    return this.currentDiagram?.status === 'draft'
  }

  get latestVersion () {
    return this.currentVersion.tags.includes('latest')
  }

  get shareLinkOptions () {
    const options: ShareLinkOptionsRequired = {
      connectionIds: null,
      diagramId: this.$queryValue('diagram'),
      domainId: this.$queryValue('domain'),
      drawer: this.$queryValue('drawer') as 'expanded' | 'collapsed' | null,
      expandedIds: null,
      filterIds: null,
      filterIdsExcluded: null,
      filterIdsIncluded: null,
      flowId: this.$queryValue('flow'),
      flowParentIds: null,
      flowPathIds: null,
      flowStepId: this.$queryValue('flow_step'),
      mode: 'diagrams',
      modelId: this.$queryValue('model'),
      objectIds: null,
      objectIdsFocused: null,
      objectTab: this.$queryValue('object_tab') as ShareLinkOptionsObjectTab,
      overlayGroupId: this.$queryValue('overlay_group'),
      overlayIdsFocused: null,
      overlayIdsHidden: null,
      overlayIdsPinned: null,
      overlayTab: null,
      preventNavigation: false,
      search: this.$queryValue('search'),
      x1: null,
      x2: null,
      y: null,
      y1: null,
      y2: null
    }

    const overlayTab = this.$queryValue('overlay_tab')
    if (overlayTab === 'tags') {
      options.overlayTab = 'tags'
    } else if (overlayTab === 'technology') {
      options.overlayTab = 'technology'
    } else if (overlayTab === 'status') {
      options.overlayTab = 'status'
    } else {
      options.overlayTab = 'tags'
      options.overlayGroupId = null
      options.overlayIdsFocused = null
      options.overlayIdsHidden = null
      options.overlayIdsPinned = null
    }

    if (this.$routeName === 'overview') {
      options.mode = 'overview'
    } else if (this.$routeName === 'diagrams') {
      options.mode = 'diagrams'
    } else if (this.$routeName === 'model-objects') {
      options.mode = 'model-objects'
    } else if (this.$routeName === 'flows') {
      options.mode = 'flows'
    } else if (this.$routeName === 'model-dependencies') {
      options.mode = 'dependencies'
    } else if (options.flowId) {
      options.mode = 'flow'
      options.preventNavigation = this.preventNavigation
    } else if (options.diagramId) {
      options.mode = 'diagram'
      options.preventNavigation = this.preventNavigation
    }

    const y = this.$queryValue('y')
    const yNumber = y ? parseFloat(y) : null
    options.y = yNumber ? Math.round(yNumber * 10) / 10 : null

    const x1 = this.$queryValue('x1')
    const x1Number = x1 ? parseFloat(x1) : null
    options.x1 = x1Number ? Math.round(x1Number * 10) / 10 : null

    const y1 = this.$queryValue('y1')
    const y1Number = y1 ? parseFloat(y1) : null
    options.y1 = y1Number ? Math.round(y1Number * 10) / 10 : null

    const x2 = this.$queryValue('x2')
    const x2Number = x2 ? parseFloat(x2) : null
    options.x2 = x2Number ? Math.round(x2Number * 10) / 10 : null

    const y2 = this.$queryValue('y2')
    const y2Number = y2 ? parseFloat(y2) : null
    options.y2 = y2Number ? Math.round(y2Number * 10) / 10 : null

    const objects = this.$queryArray('object')
    if (objects.length) {
      options.objectIds = objects
    }

    const objectsFocused = this.$queryArray('object_focus')
    if (objectsFocused.length) {
      options.objectIdsFocused = objectsFocused
    }

    const connections = this.$queryArray('connection')
    if (connections.length) {
      options.connectionIds = connections
    }

    const expanded = this.$queryArray('expanded')
    if (expanded.length) {
      options.expandedIds = expanded
    }

    const overlayPin = this.$queryArray('overlay_pin')
    if (overlayPin.length) {
      options.overlayIdsPinned = overlayPin
    }

    const overlayHide = this.$queryArray('overlay_hide')
    if (overlayHide.length) {
      options.overlayIdsHidden = overlayHide
    }

    const overlayFocus = this.$queryArray('overlay_focus')
    if (overlayFocus.length) {
      options.overlayIdsFocused = overlayFocus
    }

    const filter = this.$queryArray('filter')
    if (filter.length) {
      options.filterIds = filter
    }

    const filterInclude = this.$queryArray('filter_include')
    if (filterInclude.length) {
      options.filterIdsIncluded = filterInclude
    }

    const filterExclude = this.$queryArray('filter_exclude')
    if (filterExclude.length) {
      options.filterIdsExcluded = filterExclude
    }

    const flowPaths = this.$queryArray('flow_path')
    if (flowPaths.length) {
      options.flowPathIds = flowPaths
    }

    const flowParents = this.$queryArray('flow_parent')
    if (flowParents.length) {
      options.flowParentIds = flowParents
    }

    return options
  }

  get shareLinkUrlPrefix () {
    const url = new URL(env.SHARE_URL)
    return `${url.host}${url.pathname}`
  }

  get currentShareLinkPassword () {
    return this.currentShareLink?.password
  }

  get currentShareLinkUrl () {
    const urlPrefix = this.currentShareLink && this.shareModule.shareLinkUrls[this.currentShareLink.id]
    if (urlPrefix && this.currentShareLinkOptions) {
      return `${urlPrefix}/${this.currentShareLinkOptions.shortId}`
    } else {
      return ''
    }
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationLimitShareLinkProtected () {
    return this.currentOrganizationLimits.shareLinkProtected
  }

  get subtitle () {
    if (!this.shareLinksEnabled) {
      return ''
    } else if (this.latestVersion) {
      return 'latest version'
    } else {
      return `version ${this.currentVersion.name}`
    }
  }

  @Watch('shareLinkOptions')
  onShareLinkOptionsChanged () {
    this.generateOptions()
  }

  @Watch('currentShareLinkPassword')
  onCurrentShareLinkPasswordChanged (currentShareLinkPassword?: string) {
    this.password = currentShareLinkPassword || ''
  }

  async setPassword () {
    if (this.password.length < 8) {
      this.alertModule.pushAlert({
        color: 'error',
        message: 'Password must be at least 8 characters'
      })
      return
    }
    this.settingPassword = true
    const shareLink = await this.shareModule.shareLinkUpdate({
      landscapeId: this.currentLandscape.id,
      props: {
        password: this.password
      },
      versionId: this.currentVersion.id
    })

    this.alertModule.pushAlert({
      color: 'success',
      message: 'New pasword has been set'
    })
    this.settingPassword = false

    analytics.shareLinkUpdate.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      shareLinkProtected: shareLink.protected,
      versionLatest: this.currentVersion.tags.includes('latest'),
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }

  async mounted () {
    analytics.shareEmbedScreen.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    try {
      const [shareLink] = await Promise.all([
        this.shareModule.shareLinkFind({
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        }),
        this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.currentLandscape.id,
          props: this.shareLinkOptions,
          versionId: this.currentVersion.id
        })
      ])
      this.password = shareLink?.shareLink.password || ''
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }

  destroyed () {
    clearTimeout(this.copyTimer)
    this.copyTimer = undefined
  }

  async shareLinkToggle () {
    if (this.loadingShareToggle) {
      return
    }
    this.loadingShareToggle = true
    try {
      if (this.currentShareLink) {
        const shareLink = this.currentShareLink

        await this.shareModule.shareLinkDelete({
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
        this.shareModule.setShareLinkOptions(null)

        analytics.shareLinkDelete.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkProtected: shareLink.protected,
          versionLatest: this.currentVersion.tags.includes('latest'),
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })
      } else {
        const shareLink = await this.shareModule.shareLinkCreate({
          landscapeId: this.currentLandscape.id,
          props: {
            protected: false
          },
          versionId: this.currentVersion.id
        })
        await this.shareModule.shareLinkOptionsUpsert({
          landscapeId: this.currentLandscape.id,
          props: this.shareLinkOptions,
          versionId: this.currentVersion.id
        })

        this.password = shareLink.password || ''

        analytics.shareLinkCreate.track(this, {
          landscapeId: [this.currentLandscape.id],
          organizationId: [this.currentLandscape.organizationId],
          shareLinkProtected: false,
          versionLatest: this.currentVersion.tags.includes('latest'),
          versionModelId: this.currentVersionModel?.id || null,
          versionModelType: this.currentVersionModel?.type || null
        })
      }
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingShareToggle = false
    }
  }

  async generateLink () {
    if (this.loadingGenerateLink || !this.currentShareLink) {
      return
    }
    this.loadingGenerateLink = true
    try {
      await this.shareModule.shareLinkUpdate({
        landscapeId: this.currentLandscape.id,
        props: {},
        resetShortId: true,
        versionId: this.currentVersion.id
      })
      await this.shareModule.shareLinkOptionsUpsert({
        landscapeId: this.currentLandscape.id,
        props: this.shareLinkOptions,
        versionId: this.currentVersion.id
      })
      await this.shareModule.shareLinkFind({
        landscapeId: this.currentLandscape.id,
        versionId: this.currentVersion.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingGenerateLink = false
    }
  }

  async generateOptions () {
    if (this.loadingGenerateOptions || !this.currentShareLink) {
      return
    }
    this.loadingGenerateOptions = true
    try {
      await this.shareModule.shareLinkOptionsUpsert({
        landscapeId: this.currentLandscape.id,
        props: this.shareLinkOptions,
        versionId: this.currentVersion.id
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingGenerateOptions = false
    }
  }

  async shareProtectedToggle () {
    if (this.loadingProtectedToggle || !this.currentShareLink) {
      return
    }
    this.loadingProtectedToggle = true
    try {
      const shareLink = await this.shareModule.shareLinkUpdate({
        landscapeId: this.currentLandscape.id,
        props: {
          protected: !this.currentShareLink.protected
        },
        versionId: this.currentVersion.id
      })

      analytics.shareLinkUpdate.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId],
        shareLinkProtected: shareLink.protected,
        versionLatest: this.currentVersion.tags.includes('latest'),
        versionModelId: this.currentVersionModel?.id || null,
        versionModelType: this.currentVersionModel?.type || null
      })
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.body.message
      })
    } finally {
      this.loadingProtectedToggle = false
    }
  }

  async copyText (id: string, text: string, embedLocation?: ShareLinkFrameLocation | 'other') {
    await navigator.clipboard.writeText(text)

    analytics.shareEmbedCopyClipboard.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentOrganization.id],
      shareLinkFrameLocation: embedLocation,
      versionLatest: this.latestVersion,
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })

    this.copy = id
    clearTimeout(this.copyTimer)
    this.copyTimer = window.setTimeout(() => {
      this.copy = null
    }, 2000)
  }

  goToManageSharing () {
    this.$router.push({
      name: 'organization-manage-sharing',
      params: {
        organizationId: this.currentOrganization.id
      }
    })
  }
}
