
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { VersionModule } from '@/modules/version/store'

import { ExportFileType } from '../../../helpers/types'
import LoadingPreview from './loading-preview.vue'
import ObjectsCountBar from './objects-count-bar.vue'

@Component({
  components: {
    LoadingPreview,
    ObjectsCountBar
  },
  name: 'ShareDialogExportJsonPreview'
})
export default class ShareDialogExportJsonPreview extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly selectedType!: ExportFileType
  @Prop() readonly jsonPreview?: string
  @Prop() readonly loading?: boolean

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }
}
