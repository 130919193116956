
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { ModelModule } from '@/modules/model/store'

@Component({
  name: 'ShareDialogExportObjectsCountBar'
})
export default class ShareDialogExportObjectsCountBar extends Vue {
  modelModule = getModule(ModelModule, this.$store)

  @Prop({ default: true }) showObjectsCount!: boolean
  @Prop({ default: true }) showConnectionsCount!: boolean

  get modelObjectsCount () {
    return Object.values(this.modelModule.objects).length
  }

  get connectionsCount () {
    return Object.values(this.modelModule.connections).length
  }
}
