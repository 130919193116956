
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { RecycleScroller } from 'vue-virtual-scroller'
import { getModule } from 'vuex-module-decorators'

import TableEmptyState from '@/components/table/empty-state.vue'
import TableHeadItem, { ITableHeadItem } from '@/components/table/head-item.vue'
import TableRowItem from '@/components/table/row-item.vue'
import { AlertModule } from '@/modules/alert/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { VersionModule } from '@/modules/version/store'

import { convertCSVToJSON, CSVHeader } from '../../../helpers/csv-to-json'
import type { ExportCSVContent, ExportFileType } from '../../../helpers/types'
import LoadingPreview from './loading-preview.vue'
import ObjectsCountBar from './objects-count-bar.vue'

@Component({
  components: {
    LoadingPreview,
    ObjectsCountBar,
    RecycleScroller,
    TableEmptyState,
    TableHeadItem,
    TableRowItem
  },
  name: 'ShareDialogExportCsvPreview'
})
export default class ShareDialogExportCsvPreview extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly selectedType!: ExportFileType
  @Prop() readonly contents!: ExportCSVContent
  @Prop() readonly csvPreview?: string
  @Prop() readonly loading?: boolean

  headers: CSVHeader[] = []
  rows: Record<string, string>[] = []

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get tableHeadItems (): ITableHeadItem[] {
    return this.headers.map((header) => {
      return { id: header.key, sortable: false, title: header.title, width: '200px' }
    })
  }

  mounted () {
    this.loadData()
  }

  @Watch('csvPreview')
  onContentsChanged () {
    this.loadData()
  }

  async loadData () {
    if (this.csvPreview) {
      const csvToJsonData = convertCSVToJSON(this.csvPreview)

      this.headers = csvToJsonData.headers
      this.rows = csvToJsonData.json.map(o => {
        return {
          ...o,
          id: (o as any).Identifier
        }
      })
    } else {
      this.headers = []
      this.rows = []
    }
  }
}
