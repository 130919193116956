
import { Theme } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { ExportFileType } from '../../../helpers/types'

@Component({
  name: 'ShareDialogExportEmptyState'
})
export default class ShareDialogExportEmptyState extends Vue {
  @Prop() readonly selectedType!: ExportFileType
  @Prop() readonly selectedTheme!: Theme
}
