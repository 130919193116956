
import { IceButtonTab } from '@icepanel/component-lib'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import Tabs from '@/components/tabs.vue'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import UserInvite from '@/modules/organization/components/user-invite.vue'
import UserInvitesList from '@/modules/organization/components/user-invites-list.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { VersionModule } from '@/modules/version/store'

import { shareDialog } from '../../helpers/analytics'
import { ShareModule } from '../../store'
import Embed from './embed/embed.vue'
import Export from './export/export.vue'

@Component({
  components: {
    Dialog,
    Embed,
    Export,
    Tabs,
    UserInvite,
    UserInvitesList
  },
  name: 'ShareDialog'
})
export default class extends Vue {
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get shareDialog () {
    return this.$queryValue('share_dialog')
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.$params.versionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.versionId === this.currentVersion.id)!
  }

  get currentLandscapePermission () {
    return this.landscapeModule.landscapePermission(this.currentLandscape)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentLandscape.organizationId)!
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get currentVersionModel () {
    return Object.values(this.modelModule.objects).find(o => o.handleId === this.currentVersion.modelHandleId)
  }

  get organizationUserInvitesListStatus () {
    return this.organizationModule.organizationUserInvitesListStatus
  }

  get organizationUsersListStatus () {
    return this.organizationModule.organizationUsersListStatus
  }

  get organizationUserInvites () {
    return this.organizationModule.organizationUserInvites.filter(o => !o.revokedAt && !o.usedAt)
  }

  get showInvite () {
    return this.currentLandscapePermission === 'admin'
  }

  get showShareLink () {
    return this.currentLandscapePermission && this.currentLandscapePermission !== 'read' && (!this.currentOrganization || !this.currentVersion || this.currentOrganizationLimits.shareLinkVersions || this.currentVersion.tags.includes('latest'))
  }

  get tabs (): IceButtonTab {
    const tabs: IceButtonTab[] = []

    if (this.showInvite) {
      tabs.push({ icon: 'fad-user-plus', id: 'invite', text: 'Invite' })
    }
    if (this.showShareLink) {
      tabs.push({ icon: 'fad-share', id: 'link', text: 'Share Link' })
    }
    tabs.push({ icon: 'fad-download', id: 'export', text: 'Export' })

    return tabs
  }

  selectTab (id: string) {
    this.$replaceQuery({
      share_dialog: id
    })
  }

  open () {
    if (this.showInvite && this.currentOrganizationId && this.organizationModule.organizationUserInvitesListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUserInvitesListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUserInvitesList(this.currentOrganizationId)
    }
  }

  opened () {
    shareDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId],
      versionLatest: this.currentVersion.tags.includes('latest'),
      versionModelId: this.currentVersionModel?.id || null,
      versionModelType: this.currentVersionModel?.type || null
    })
  }
}
