
import { Theme } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { ExportFileType } from '../../../helpers/types'
import LoadingPreview from './loading-preview.vue'

@Component({
  components: {
    LoadingPreview
  },
  name: 'ShareDialogExportLoadingPreview'
})
export default class ShareDialogExportLoadingPreview extends Vue {
  @Prop() readonly selectedType!: ExportFileType
  @Prop({ default: 'dark' }) readonly selectedTheme!: Theme
  @Prop() readonly loading!: boolean
}
